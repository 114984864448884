import React from 'react';
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import '../Screens.css';
import APIClient from '../../api.service';
import { Spin } from 'antd';
import appConfig from '../../config';

export default function Home() {
  const [stats, setStats] = React.useState(null);
  const [gallonsUsed, setGallonsUsed] = React.useState(0);

  const { data, isLoading, errorMessage } = useOpenWeather({
    key: '15b5fe70aa89ed5ad57dba285ba99150',
    lat: appConfig.REACT_APP_LAT,
    lon: appConfig.REACT_APP_LON,
    lang: 'en',
    unit: 'imperial', // values are (metric, standard, imperial)
  });

  React.useEffect(() => {
    let apiClient = new APIClient();
    apiClient.getSystemStats().then((response) => {
      setStats(response);
      setGallonsUsed(0);
      let totalGallons = 0;
      for (const station of response.stationStats) {
        let time = station.actualRunTime / 60;
        totalGallons = time * station.flowRate + totalGallons;
      }
      setGallonsUsed(totalGallons);
    });
  }, []);

  return stats ? (
    <div className="pageContent">
      <h1>Welcome to the sprinkler control center.</h1>
      <div id="infoRow">
        <div id="weather" style={{ minWidth: '40em' }}>
          <ReactWeather
            isLoading={isLoading}
            errorMessage={errorMessage}
            data={data}
            lang="en"
            locationLabel={appConfig.REACT_APP_WEATHER_LOCATION_NAME}
            unitsLabels={{ temperature: 'F', windSpeed: 'm/h' }}
            showForecast
          />
        </div>
        <div id="systemSummary">
          <h2>Irrigation System Summary</h2>
          <h3>Total Satellites: {stats.satelliteCount}</h3>
          <h3>Total Stations: {stats.stationCount}</h3>
          <h3>
            Approximate Daily Water Usage: {gallonsUsed.toLocaleString('en-US')}{' '}
            gal
          </h3>
          <h3>
            Approximate Monthly Water Usage:{' '}
            {(gallonsUsed * 30).toLocaleString('en-US')} gal
          </h3>
        </div>
      </div>
    </div>
  ) : (
    <Spin
      style={{ justifyContent: 'center', width: '100%' }}
      tip="Loading..."
    />
  );
}
