import React, { Component } from 'react';
import { Button, Form, Input, TimePicker, Spin } from 'antd';
import 'antd/dist/antd.css';
import { LoadingOutlined } from '@ant-design/icons';
import APIClient from '../../../api.service';
import moment from 'moment';
import LoadScheduleModal from '../Modals/LoadScheduleModal';
import FirmwareUploadModal from '../Modals/FirmwareUploadModal';

export default class General extends Component {
  constructor(props) {
    super();

    this.state = {
      validateSystemCapacity: 'success',
      validateTimeOnPercent: 'success',
      validateGlobalStartTime: 'success',
      validateBuildScheduleStartTime: 'success',
      scheduleSynced: false,
      systemCapacity: props.generalConfig
        ? props.generalConfig[0].data[0].value
        : 0,
      timeOnPercent: props.generalConfig
        ? props.generalConfig[1].data[0].value
        : 0,
      globalStartTime: props.generalConfig
        ? props.generalConfig[2].data[0].value
        : '00:00',
      buildScheduleStartTime: props.generalConfig
        ? props.generalConfig[3].data[0].value
        : '00:00',
      scheduleName: props.activeSchedule ? props.activeSchedule.name : '',
      scheduleDescription: props.activeSchedule
        ? props.activeSchedule.description
        : '',
    };

    this.systemCapacity = this.setSystemCapacity.bind(this);
    this.timeOnPercent = this.setTimeOnPercent.bind(this);
    this.globalStartTime = this.setGlobalStartTime.bind(this);
    this.buildScheduleStartTime = this.setBuildScheduleStartTime.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.generalConfig !== prevProps.generalConfig ||
      this.props.activeSchedule !== prevProps.activeSchedule
    ) {
      this.setState({
        systemCapacity: this.props.generalConfig[0].data[0].value,
      });
      this.setState({
        timeOnPercent: this.props.generalConfig[1].data[0].value,
      });
      this.setState({
        globalStartTime: this.props.generalConfig[2].data[0].value,
      });
      this.setState({
        buildScheduleStartTime: this.props.generalConfig[3].data[0].value,
      });

      this.setState({
        scheduleName: this.props.activeSchedule
          ? this.props.activeSchedule.name
          : '',
      });
      this.setState({
        scheduleDescription: this.props.activeSchedule
          ? this.props.activeSchedule.description
          : '',
      });
    }
  }

  setSystemCapacity = (value) => {
    this.props.generalConfig[0].data[0].value = value.target.value;
    this.setState({
      systemCapacity: value.target.value,
      validateSystemCapacity: 'warning',
    });
  };

  setTimeOnPercent = (value) => {
    this.props.generalConfig[1].data[0].value = value.target.value;
    this.setState({
      timeOnPercent: value.target.value,
      validateTimeOnPercent: 'warning',
    });
  };

  setGlobalStartTime = (value) => {
    this.props.generalConfig[2].data[0].value = value
      .add(4, 'hours')
      .format('HH:mm:ss');
    this.setState({
      globalStartTime: value,
      validateGlobalStartTime: 'warning',
    });
  };

  setBuildScheduleStartTime = (value) => {
    this.props.generalConfig[3].data[0].value = value
      .add(4, 'hours')
      .format('HH:mm:ss');
    this.setState({
      buildScheduleStartTime: value,
      validateBuildScheduleStartTime: 'warning',
    });
  };

  handleSubmit = (event) => {
    let apiClient = new APIClient();

    if (this.props.activeSchedule) {
      this.props.activeSchedule.name = this.state.scheduleName;
      this.props.activeSchedule.description = this.state.scheduleDescription;
      this.props.activeSchedule.active = 1;
      this.props.activeSchedule.json = JSON.stringify(this.props.config);
      apiClient.updateSavedSchedule(this.props.activeSchedule);
    } else {
      apiClient.saveNewSchedule({
        name: this.state.scheduleName,
        description: this.state.scheduleDescription,
        json: JSON.stringify(this.props.config),
        active: 1,
      });
    }

    apiClient.setConfiguration(this.props.generalConfig).then((response) => {
      this.setState({
        validateSystemCapacity: 'success',
        validateTimeOnPercent: 'success',
        validateGlobalStartTime: 'success',
        validateBuildScheduleStartTime: 'success',
        scheduleSynced: true,
      });
    });
  };

  setScheduleName = (event) => {
    this.setState({ scheduleName: event.target.value });
  };

  setScheduleDescription = (event) => {
    this.setState({ scheduleDescription: event.target.value });
  };

  handler = (name, desc, conf, active) => {
    this.setState({ scheduleName: name, scheduleDescription: desc });
    this.props.handler(conf, active);
  };

  render() {
    const formItemLayout = {
      labelCol: { span: 11, offset: 1 },
      wrapperCol: { span: 10, offset: 1 },
    };
    return this.props.generalConfig ? (
      <div style={{ display: 'flex' }}>
        <Form
          id="generalConfig"
          onFinish={this.handleSubmit}
          {...formItemLayout}
        >
          <Form.Item
            label="System Capcity: (GPM):"
            validateStatus={this.state.validateSystemCapacity}
            hasFeedback
          >
            <Input
              type="text"
              value={this.state.systemCapacity}
              onChange={this.setSystemCapacity}
            />
          </Form.Item>
          <Form.Item
            label="Time On (%):"
            validateStatus={this.state.validateTimeOnPercent}
            hasFeedback
          >
            <Input
              type="text"
              value={this.state.timeOnPercent}
              onChange={this.setTimeOnPercent}
            />
          </Form.Item>
          <Form.Item
            label="System Start Time:"
            validateStatus={this.state.validateGlobalStartTime}
            hasFeedback
          >
            <TimePicker
              use12Hours
              format="h:mm:ss A"
              allowClear={false}
              onChange={this.setGlobalStartTime}
              value={moment(this.state.globalStartTime, 'hh:mm:ss').subtract(
                4,
                'hours',
              )}
            />
          </Form.Item>
          <Form.Item
            label="Build Schedule Start Time:"
            validateStatus={this.state.validateBuildScheduleStartTime}
            hasFeedback
          >
            <TimePicker
              use12Hours
              format="h:mm:ss A"
              allowClear={false}
              onChange={this.setBuildScheduleStartTime}
              value={moment(
                this.state.buildScheduleStartTime,
                'hh:mm:ss',
              ).subtract(4, 'hours')}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 6, offset: 8 }}>
            <Button htmlType="submit">Save</Button>
          </Form.Item>
        </Form>
        <Form
          id="scheduleSelect"
          onFinish={this.handleSubmit}
          {...formItemLayout}
        >
          <Form.Item
            label="Schedule Name:"
            validateStatus={'success'}
            hasFeedback
          >
            <Input
              type="text"
              value={this.state.scheduleName}
              onChange={this.setScheduleName}
            />
          </Form.Item>
          <Form.Item
            label="Schedule Description:"
            validateStatus={'success'}
            hasFeedback
          >
            <Input
              type="textBox"
              value={this.state.scheduleDescription}
              onChange={this.setScheduleDescription}
            />
          </Form.Item>
          <Form.Item>
            <LoadScheduleModal
              scheduleName={this.state.scheduleName}
              scheduleDescription={this.state.scheduleDescription}
              activeSchedule={this.props.activeSchedule}
              schedules={this.props.schedules}
              config={this.props.config}
              handler={this.handler}
            />
          </Form.Item>
          <Form.Item hidden={this.props.roleID != 1}>
            <FirmwareUploadModal />
          </Form.Item>
        </Form>
      </div>
    ) : (
      <Spin
        style={{ justifyContent: 'center', width: '100%' }}
        tip="Loading..."
      />
    );
  }
}
