import React, { Component } from 'react';
import { Modal, Button, List, Spin, Form, Input, Select, message, InputNumber } from 'antd';
import 'antd/dist/antd.css';
import APIClient from '../../../api.service';
import StationModal from './StationModal';
import './Modals.css';

export default class SatelliteModal extends Component {
  constructor(props) {
    super();

    let filteredStations = [];
    if (props.stations) {
      props.stations.forEach((station) => {
        if (station.satelliteID === props.satellite.ID) {
          filteredStations.push(station);
        }
      });
    }

    filteredStations.sort((a, b) => a.stationID - b.stationID);

    this.state = {
      show: false,
      flowRate: props.satellite.flowRate,
      priority: props.satellite.priority,
      scheduleConditionID: props.satellite.scheduleConditionID,
      stations: filteredStations,
      validateFlowRate: 'success',
      validateScheduleConditionID: 'success',
      validatePriority: 'success',
      isTesting: false,
      testDuration: 10, 
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.setMaxFlowRate = this.setMaxFlowRate.bind(this);
    this.setPriority = this.setPriority.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  // Default run time of each sprinkler head is 10 even if its null in the satellite prompt
  setTestDuration = (value) => {
    this.setState({ testDuration: value === null ? 10 : value });
  };

  runTestSequence = async () => {
    try {
      const apiClient = new APIClient();
      const parentStations = this.state.stations.filter((st) => st.parentID === null);

      const response = await apiClient.testSatellite(
        this.props.satellite.ID,
        this.state.testDuration
      );

      /**
       * What happens in the backend when this request is made:
       *
       * 1. test_sequence.php receives the request and checks for active commands by querying:
       * - tdp_requests table using the helper function check_for_pending_command__all_units()
       * → ensures no other test or command is currently running.
       *
       * 2. It fetches station data from the stations table:
       * - WHERE satelliteID = ? AND parentID IS NULL
       *
       * 3. If stations are found, it queues a test sequence by calling:
       * → tdp->run_test_sequence(satelliteID, stationIDs, duration)
       *
       * 4. That method inserts into:
       * - tdp_requests (command created)
       * - tdp_sms_queue (queued for polling )
       *
       * 5. The response includes:
       * - type: "success"
       * - stations_count: Number of stations to test
       */

      if (response.type === 'success') {
        const stationCount = response.stations_count || parentStations.length;
        const testDurationPerStation = parseInt(this.state.testDuration) || 10;
        // multiplying by number of stations and duration also adding a 2 sec buffer before transition of concurrent heads
        const testDurationSeconds = stationCount * (testDurationPerStation + 2);

        message.success(`Test sequence started for Satellite ${this.props.satellite.ID} (${stationCount} stations)`);
        
        // Start a timer to notify when the test would complete
        setTimeout(() => {
          message.success(`Test sequence for Satellite ${this.props.satellite.ID} should be complete`);
        }, testDurationSeconds * 1000 + 2000);
      } else {
        message.error(response.message || 'Failed to queue test sequence');
      }
    } catch (err) {
      console.error('Error during test sequence:', err);
      message.error('Unexpected error during test sequence');
    }
  };

  handleSubmit = () => {
    const { scheduleConditionID, priority, flowRate } = this.state;
    const updatedSatellite = {
      ...this.props.satellite,
      scheduleConditionID: scheduleConditionID,
      priority: priority,
      flowRate: flowRate,
    };

    let apiClient = new APIClient();
    apiClient.updateSatellite(updatedSatellite).then((response) => {
      this.setState({ validateFlowRate: 'success' });
      this.setState({ validatePriority: 'success' });
      this.setState({ validateScheduleConditionID: 'success' });
      this.hideModal();
    }).catch(error => {
      console.error("Failed to update satellite:", error);
      message.error(`Failed to update Satellite ${this.props.satellite.ID}`);
      this.setState({ validateFlowRate: 'error' });
      this.setState({ validatePriority: 'error' });
      this.setState({ validateScheduleConditionID: 'error' });
    });
  };
  handleCancel = () => {
    this.setState({ flowRate: this.props.satellite.flowRate });
    this.setState({
      scheduleConditionID: this.props.satellite.scheduleConditionID,
    });
    this.setState({ priority: this.props.satellite.priority });
    this.setState({ validateFlowRate: 'success' });
    this.setState({ validatePriority: 'success' });
    this.setState({ validateScheduleConditionID: 'success' });
    this.hideModal();
  };

  setMaxFlowRate = (value) => {
    this.setState({ flowRate: value.target.value });
    this.setState({ validateFlowRate: 'warning' });
  };

  setPriority = (value) => {
    this.setState({ priority: value.target.value });
    this.setState({ validatePriority: 'warning' });
  };

  setScheduleConditionID = (value) => {
    this.setState({ scheduleConditionID: value });
    this.setState({ validateScheduleConditionID: 'warning' });
  };

  render() {
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
    };
    const { stations } = this.state;

    return this.props.satellite ? (
      <div sx={{ width: '30rem', display: 'flex' }}>
        <Button
          onClick={this.showModal}
          style={{
            textAlign: 'left',
            width: '30rem',
            height: '40px',
            marginBottom: '0px',
            padding: '0',
            borderStyle: 'none',
          }}
        >
          <List.Item.Meta
            title={<b>Satellite {this.props.satellite.ID}</b>}
            description={
              <p>
                Priority: {this.props.satellite.priority} │ Flow Rate:{' '}
                {this.props.satellite.flowRate} │ Schedule Condition:{' '}
                <span style={{ color: 'green' }}>
                  (
                  {this.props.satellite.scheduleConditionID == 1
                    ? 'On Schedule'
                    : 'Off Schedule'}
                  )
                </span>
              </p>
            }
          />
        </Button>

        <Button
          type="primary"
          onClick={this.runTestSequence}
          style={{ marginLeft: '10px', alignSelf: 'center' }}
        >
          Test Stations
        </Button>

        <Modal
          title={'Satellite ' + this.props.satellite.ID}
          centered
          visible={this.state.show}
          okButtonProps={{
            form: 'satellite' + this.props.satellite.ID,
            key: 'submit',
            htmlType: 'submit',
          }}
          onCancel={this.handleCancel}
          width={520}
          okText={'Save'}
          maskClosable={this.handleCancel}
          closable={false}
        >
          <Form
            id={'satellite' + this.props.satellite.ID}
            onFinish={this.handleSubmit}
            {...formItemLayout}
          >
            <Form.Item
              label="Max Flowrate (GPM):"
              validateStatus={this.state.validateFlowRate}
              hasFeedback
            >
              <Input
                type="text"
                value={this.state.flowRate}
                onChange={this.setMaxFlowRate}
              />
            </Form.Item>
            <Form.Item
              label="Priority:"
              validateStatus={this.state.validatePriority}
              hasFeedback
            >
              <Input
                type="text"
                value={this.state.priority}
                onChange={this.setPriority}
              />
            </Form.Item>
            <Form.Item
              label="Schedule State:"
              validateStatus={this.state.validateScheduleConditionID}
              hasFeedback
            >
              <Select
                value={this.state.scheduleConditionID}
                onChange={this.setScheduleConditionID}
              >
                <Select.Option value="1">On Schedule</Select.Option>
                <Select.Option value="0">Off Schedule</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Test Duration (sec):" wrapperCol={{ span: 6 }}>
              <InputNumber
                aria-label="Test Duration (sec):"
                min={5}
                max={60}
                value={this.state.testDuration}
                onChange={this.setTestDuration}
              />
            </Form.Item>

            <Form.Item
              label="Run Test:"
              help={`Run ${stations.filter((s) => s.parentID == null).length} stations in order`}
              wrapperCol={{ span: 14 }}
            >
              <Button
                type="primary"
                onClick={this.runTestSequence}
              >
                Test Stations
              </Button>
            </Form.Item>

          </Form>
        </Modal>
      </div>
    ) : (
      <Spin
        style={{ justifyContent: 'center', width: '100%' }}
        tip="Loading..."
      />
    );
  }
}
