import React, { useState, useEffect } from 'react';
import './Navigation.css';
import HomeIcon from '@material-ui/icons/Home';
import EventNoteIcon from '@mui/icons-material/EventNote';
import MapIcon from '@mui/icons-material/Map';
import ConstructionIcon from '@mui/icons-material/Construction';
import SecuritySharpIcon from '@mui/icons-material/SecuritySharp';
import { Menu, message, Popconfirm, Button } from 'antd';
import { CloudOutlined } from '@ant-design/icons';
import APIClient from '../../api.service';

const Navigation = () => {
  const current = window.location.pathname;
  const [rainHoldActive, setRainHoldActive] = useState(false);
  const [isRainHoldButtonLoading, setIsRainHoldButtonLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);

  // Fetch rain hold status from database
  const fetchRainHoldStatus = async () => {
    try {
      const apiClient = new APIClient();
      const isActive = await apiClient.getRainHoldStatus();
      setRainHoldActive(isActive);
    } catch (error) {
      console.error("Failed to fetch rain hold status:", error);
    } finally {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    // Polling every 10 seconds 
    fetchRainHoldStatus();
    const intervalId = setInterval(fetchRainHoldStatus, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const handleClick = (e) => {
    window.location.pathname = e.key;
  };

  const deploySchedule = async () => {
    try {
      const apiClient = new APIClient();
      await apiClient.commitSchedule();
      message.success('Schedule successfully deployed.');
    } catch {
      message.error('Schedule failed to deploy.');
    }
  };

  const toggleRainHold = async () => {
    setIsRainHoldButtonLoading(true);
    try {
      const apiClient = new APIClient();
      // updating the system_properties table 
      const newStatus = !rainHoldActive;
      const dbUpdateSuccess = await apiClient.setRainHoldStatus(newStatus);
      if (!dbUpdateSuccess) {
        console.error("Failed to update database status");
      }
      // sending the command to pause/resume the system
      const response = await apiClient.perform('post', '/commands', {
        function: 'toggle_system',
        action: rainHoldActive ? 'resume' : 'pause',
        time: 0,
      });
      if (response.type === 'success') {
        setRainHoldActive(newStatus);
        message.success(
          newStatus ? 'Rain hold activated' : 'Schedule resumed'
        );
      } else {
        // If the command failed, revert the database state
        await apiClient.setRainHoldStatus(rainHoldActive);
        message.error('Failed to toggle rain hold.');
      }
    } catch (error) {
      console.error("Error toggling rain hold:", error);
      message.error('Failed to toggle rain hold.');
    } finally {
      setIsRainHoldButtonLoading(false);
    }
  };

  // Determine the button class based on the rain hold state
  const buttonClass = rainHoldActive ? 'rain-hold-active' : 'rain-hold';
  const mainNavItems = [
    {
      key: '/',
      label: (
        <span className="menu-item">
          <HomeIcon fontSize="small" className="menu-icon" />
          Home
        </span>
      ),
    },
    {
      key: '/Schedule',
      label: (
        <span className="menu-item">
          <EventNoteIcon fontSize="small" className="menu-icon" />
          Schedule
        </span>
      ),
      children: [
        {
          key: '/Schedule/Timeline',
          label: 'Timeline',
        },
        {
          key: '/Schedule/List',
          label: 'List',
        },
        {
          key: '/Schedule/RadarSimulation',
          label: 'Radar Simulation',
        },
      ],
    },
    {
      key: '/Map',
      label: (
        <span className="menu-item">
          <MapIcon fontSize="small" className="menu-icon" />
          Map
        </span>
      ),
    },
    {
      key: '/Configuration',
      label: (
        <span className="menu-item">
          <ConstructionIcon fontSize="small" className="menu-icon" />
          Configuration
        </span>
      ),
    },
    {
      key: '/SystemReports',
      label: (
        <span className="menu-item">
          <SecuritySharpIcon fontSize="small" className="menu-icon" />
          System Reports
        </span>
      ),
    },
  ];

  const deployItem = {
    key: 'commit',
    label: (
      <span className="menu-item">
        <SecuritySharpIcon fontSize="small" className="menu-icon" />
        Deploy Schedule
      </span>
    ),
  };


  if (isPageLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="header-container">
      <Menu
        defaultOpenKeys={[current]}
        selectedKeys={[current]}
        mode="horizontal"
        theme="dark"
        onClick={handleClick}
        items={mainNavItems}
        className="main-menu"
      />

      <div className="button-container">
        <Button
          data-testid="rain-hold-button"
          type="primary"
          icon={<CloudOutlined />}
          className={buttonClass}
          onClick={toggleRainHold}
          loading={isRainHoldButtonLoading}
          style={{ 
            backgroundColor: rainHoldActive ? '#ff4d4f' : '#1890ff',
            borderColor: rainHoldActive ? '#ff4d4f' : '#1890ff' 
          }}
        >
          {rainHoldActive ? 'Resume Schedule' : 'Rain Hold'}
        </Button>

        <Popconfirm
          title="Are you sure you want to deploy the schedule?"
          description="Confirm deploy schedule"
          onConfirm={deploySchedule}
          okText="Confirm"
        >
          <Menu
            mode="horizontal"
            theme="dark"
            items={[deployItem]}
            className="deploy-menu"
          />
        </Popconfirm>
      </div>
    </div>
  );
};

export default Navigation;
