import './ScheduleList.css';
import Menu from '@mui/material/Menu';
import React from 'react';
import APIClient from '../../api.service';
import { List } from 'antd';
import moment from 'moment';
import { Divider, MenuItem, MenuList } from '@mui/material';

export default function ScheduleList() {
  const [allData, setAllData] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterOption, setFilterOption] = React.useState('time');
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    const fetchSchedule = async () => {
      try {
        let apiClient = new APIClient();
        const schedule = await apiClient.getCurrentSchedule();
        sortData(schedule);
      } catch (e) {
        console.error(`An error occured, ${e.message}`);
      }
    };
    fetchSchedule();
  }, [filterOption]);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function sortData(schedule) {
    switch (filterOption) {
      case 'numerical order':
        setAllData(
          schedule.sort((a, b) => {
            if (a?.satelliteID != b?.satelliteID) {
              return a?.satelliteID > b?.satelliteID ? 1 : -1;
            }
            return Number(a?.stationID) > Number(b?.stationID) ? 1 : -1;
          }),
        );
        break;
      default:
        setAllData(
          schedule.sort((a, b) => (a?.startTime > b?.startTime ? 1 : -1)),
        );
    }
  }

  function handleClose(event) {
    setAnchorEl(null);
    setFilterOption(event.target.id);
  }

  return (
    <>
      <div>
        <button
          id="filter-button"
          aria-controls={open ? 'filter-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          Filter
        </button>
        <Menu
          id="filter-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuList dense>
            <MenuItem id="time" onClick={handleClose}>
              Time
            </MenuItem>
            <Divider />
            <MenuItem id="numerical order" onClick={handleClose}>
              Numerical Order
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
      <div className="data">
        {allData.length === 0 ? (
          <div></div>
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={allData}
            renderItem={(station) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <b>
                      Satellite {station.satelliteID} - Station{' '}
                      {station.stationID}
                    </b>
                  }
                  description={
                    <p>
                      Run Time:{' '}
                      {moment(moment.utc(station.startTime).local()).format(
                        'h:mm a',
                      )}{' '}
                      -{' '}
                      {moment(moment.utc(station.endTime).local()).format(
                        'h:mm a',
                      )}
                    </p>
                  }
                />
              </List.Item>
            )}
          />
        )}
      </div>
    </>
  );
}
