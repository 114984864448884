import react, { Component } from 'react';
import { Spin, List } from 'antd';
import 'antd/dist/antd.css';
import StationModal from '../Modals/StationModal';
import APIClient from '../../../api.service';

export default class Stations extends Component {
  constructor(props) {
    super();

    this.state = {
      validateStations: 'success',
    };
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 3 },
    };
    return this.props.stations ? (
      <div>
        <List
          itemLayout="horizontal"
          dataSource={this.props.stations}
          renderItem={(station) => (
            <List.Item>
              <StationModal
                station={station}
                stationTypes={this.props.stationTypes}
              />
            </List.Item>
          )}
        />
      </div>
    ) : (
      <Spin
        style={{ justifyContent: 'center', width: '100%' }}
        tip="Loading..."
      />
    );
  }
}
