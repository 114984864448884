import React from 'react';
import { Divider, DatePicker, Button, Select, Space, message } from 'antd';
import '../Screens.css';
import APIClient from '../../api.service';
import { Spin, Card, Descriptions, Space as AntSpace, List } from 'antd';
import {
  CheckCircleOutlined,
  CheckOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import CombinedChart from './Components/CombinedChart';
const { RangePicker } = DatePicker;
const { Option } = Select;

export default function SystemStatus() {
  const [cellStats, setCellStats] = React.useState(null);
  const [pumpStats, setPumpStats] = React.useState([]);
  const [overtempStats, setOverTempStats] = React.useState([]);
  const [highPressureStats, setHighPressureStats] = React.useState([]);
  const [lowPressureStats, setLowPressureStats] = React.useState([]);
  const [highFlowStats, setHighFlowStats] = React.useState([]);
  const [phaseLossStats, setPhaseLossStats] = React.useState([]);
  const [powerUpStats, setPowerUpStats] = React.useState([]);
  const [pumpPressureAndFlowData, setPumpPressureAndFlowData] = React.useState([]);
  
  // Adding these variables for pump time series data 
  const [jockeyPumpData, setJockeyPumpData] = React.useState([]);
  const [pumpOneData, setPumpOneData] = React.useState([]);
  const [pumpTwoData, setPumpTwoData] = React.useState([]);
  
  // Date filter states
  const [loading, setLoading] = React.useState(false);
  const [dateRange, setDateRange] = React.useState([
    moment().subtract(1, 'day'),
    moment()
  ]);
  const [quickSelect, setQuickSelect] = React.useState('1d');
  const [dataTimeRange, setDataTimeRange] = React.useState({
    start: null,
    end: null
  });

  React.useEffect(() => {
    let apiClient = new APIClient();

    apiClient.getSatelliteCellStatuses().then((response) => {
      setCellStats(
        response.sort((a, b) =>
          parseInt(a.satelliteID) > parseInt(b.satelliteID) ? 1 : -1,
        ),
      );
    });

    // Initial data load
    fetchPumpData();
  }, []);

  const fetchPumpData = async (startDate = null, endDate = null) => {
    setLoading(true);
    try {
      let apiClient = new APIClient();
      let url = '/pump_house_data';
      
      // Adding Dates to URL 
      if (startDate && endDate) {
        const formattedStart = startDate.format('YYYY-MM-DD HH:mm:ss');
        const formattedEnd = endDate.format('YYYY-MM-DD HH:mm:ss');
        url = `/pump_house_data?startDate=${encodeURIComponent(formattedStart)}&endDate=${encodeURIComponent(formattedEnd)}`;
      }
      
      const response = await apiClient.perform('get', url);
      
      if (response && response.length > 0) {
        processPumpData(response);
        
        // Set the data time range for display
        if (response.length > 0) {
          setDataTimeRange({
            start: moment(moment.utc(response[0].reportTime).local()).format('M/D/YY h:mm a'),
            end: moment(moment.utc(response[response.length - 1].reportTime).local()).format('M/D/YY h:mm a')
          });
        }
        
        message.success('Data loaded successfully');
      } else {
        // Clear the data arrays to show empty states
        setPumpPressureAndFlowData([]);
        setJockeyPumpData([]);
        setPumpOneData([]);
        setPumpTwoData([]);
        setPowerUpStats([]);
        setPhaseLossStats([]);
        setHighFlowStats([]);
        setLowPressureStats([]);
        setHighPressureStats([]);
        setOverTempStats([]);
        setPumpStats([]);
        
        // Reset data time range
        setDataTimeRange({
          start: null,
          end: null
        });
        
        message.info('No data available for the selected time range');
      }
    } catch (error) {
      console.error('Error fetching pump data:', error);
      message.error('Failed to load data');
      
      // Also clear data on error
      setPumpPressureAndFlowData([]);
      setJockeyPumpData([]);
      setPumpOneData([]);
      setPumpTwoData([]);
    } finally {
      setLoading(false);
    }
  };

  const processPumpData = (response) => {
    let powStat = [];
    let phaseStat = [];
    let highFlowStat = [];
    let lowPressStat = [];
    let highPressStat = [];
    let ot = [];
    let pstat = [];
    let pumpPressFlow = [];
    
    // Collecting all the statuses of three pumps throught time
    let jockeyData = [];
    let pumpOne = [];
    let pumpTwo = [];

    for (let i = 0; i < response.length; i++) {
      let item = response[i];
      // Converting into the local time zone 
      let reportTime = moment(moment.utc(item.reportTime).local()).format('M/D/YY h:mm a');
      pumpPressFlow.push({
        datetime: reportTime,
        name: i,
        pressure: parseInt(item.pressureStatus),
        flow: parseInt(item.flowRate),
      });
      
      // Collecting Pump States data 
      jockeyData.push(parseInt(item.jockeyPumpStatus) || 0);
      pumpOne.push(parseInt(item.pumpOneStatus) || 0);
      pumpTwo.push(parseInt(item.pumpTwoStatus) || 0);
      if (parseInt(item.powerUpStats)) { powStat.push({ time: reportTime }); }
      if (parseInt(item.phaseLossAlarm)) { phaseStat.push({ time: reportTime }); }
      if (parseInt(item.highFlowAlarmStatus)) { highFlowStat.push({ time: reportTime }); }
      if (parseInt(item.lowPressureAlarmStatus)) { lowPressStat.push({ time: reportTime }); }
      if (parseInt(item.highPressureStats)) { highPressStat.push({ time: reportTime }); }

      if (parseInt(item.overtempOneStatus)) { ot.push({ name: 'Main 1', time: reportTime }); }
      if (parseInt(item.overtempTwoStatus)) { ot.push({ name: 'Main 2', time: reportTime }); }

      // For current view using the latest pump status
      if (i === response.length - 1) {
        pstat = [
          { name: 'Jockey', status: parseInt(item.jockeyPumpStatus) || 0 },
          { name: 'Main 1', status: parseInt(item.pumpOneStatus) || 0 },
          { name: 'Main 2', status: parseInt(item.pumpTwoStatus) || 0 }
        ];
      }
    }

    setPowerUpStats(powStat);
    setPhaseLossStats(phaseStat);
    setHighFlowStats(highFlowStat);
    setLowPressureStats(lowPressStat);
    setHighPressureStats(highPressStat);
    setOverTempStats(ot);
    setPumpStats(pstat);
    setPumpPressureAndFlowData(pumpPressFlow);
    setJockeyPumpData(jockeyData);
    setPumpOneData(pumpOne);
    setPumpTwoData(pumpTwo);
  };

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      setQuickSelect('custom');
    }
  };

  const handleQuickSelectChange = (value) => {
    setQuickSelect(value);
    
    let start, end;
    end = moment();
    
    switch (value) {
      case '1d':
        start = moment().subtract(1, 'day');
        break;
      case '3d':
        start = moment().subtract(3, 'days');
        break;
      case '1w':
        start = moment().subtract(1, 'week');
        break;
      case '2w':
        start = moment().subtract(2, 'weeks');
        break;
      case '1m':
        start = moment().subtract(1, 'month');
        break;
      default:
        return;
    }
    
    setDateRange([start, end]);
  };

  const handleApplyFilter = () => {
    if (dateRange && dateRange.length === 2) {
      fetchPumpData(dateRange[0], dateRange[1]);
    }
  };

  return cellStats ? (
    <div className="pageContent">
      <div style={{ display: 'flex' }}>
        <Card
          title="Cellular Status"
          style={{ width: '25rem', minWidth: '25rem' }}
        >
          {cellStats.map((satellite, index) => {
            let status = moment
              .utc(satellite.lastCellPoll)
              .isAfter(moment().subtract(10, 'minutes'));
            return (
              <Card
                key={index}
                style={{ marginTop: '1em', fontSize: '14px', padding: '0' }}
                type="inner"
                headStyle={{ fontSize: '15px', fontWeight: '700' }}
                title={
                  <>
                    {status ? (
                      <CheckCircleOutlined style={{ color: 'green' }} />
                    ) : (
                      <WarningOutlined style={{ color: 'red' }} />
                    )}{' '}
                    Satellite: {satellite.satelliteID}{' '}
                  </>
                }
              >
                <Descriptions>
                  <Descriptions.Item span={3} label="Signal Strength">
                    {satellite.cellSignalStrength}
                  </Descriptions.Item>
                  <Descriptions.Item span={3} label="Last Communication">
                    {moment.utc(satellite.lastCellPoll).fromNow()}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            );
          })}
        </Card>
        <Divider type="vertical" />
        <div style={{ display: 'block', width: '75%' }}>
          <AntSpace direction="vertical" size="large" style={{ display: 'flex' }}>
            {/* Date Filter Controls */}
            <Card size="small" title="Historical Data Filters" style={{ marginBottom: '10px' }}>
              <Space>
                <Select 
                  value={quickSelect} 
                  onChange={handleQuickSelectChange}
                  style={{ width: 120 }}
                >
                  <Option value="1d">Last 24h</Option>
                  <Option value="3d">Last 3 Days</Option>
                  <Option value="1w">Last Week</Option>
                  <Option value="2w">Last 2 Weeks</Option>
                  <Option value="1m">Last Month</Option>
                  <Option value="custom">Custom</Option>
                </Select>
                
                <RangePicker 
                  value={dateRange}
                  onChange={handleDateRangeChange}
                  showTime
                  format="YYYY-MM-DD HH:mm"
                />
                
                <Button type="primary" onClick={handleApplyFilter} loading={loading}>
                  Apply Filter
                </Button>
              </Space>
              
              {dataTimeRange.start && (
                <div style={{ marginTop: '10px', fontSize: '12px', color: '#888' }}>
                  Showing data from {dataTimeRange.start} to {dataTimeRange.end}
                </div>
              )}
            </Card>
            
            {pumpPressureAndFlowData.length > 0 ? (
              <CombinedChart 
                pressureData={pumpPressureAndFlowData.map((timeseries) => timeseries.pressure)}
                flowData={pumpPressureAndFlowData.map((timeseries) => timeseries.flow)}
                xAxisData={pumpPressureAndFlowData.map((timeseries) => timeseries.datetime)} 
                jockeyPumpData={jockeyPumpData}
                pumpOneData={pumpOneData}
                pumpTwoData={pumpTwoData}
              />
            ) : (
              <Card style={{ textAlign: 'center', padding: '30px 0' }}>
                <WarningOutlined style={{ fontSize: '32px', color: '#faad14', marginBottom: '16px' }} />
                <h3>No pump data available for the selected time range</h3>
                <p>Try selecting a different date range or check that the pump system is operational.</p>
              </Card>
            )}
            
            <List
              size="large"
              header={<div>Current Pump Status</div>}
              bordered
              dataSource={pumpStats}
              renderItem={(pump) => (
                <List.Item>
                  {pump.status ? (
                    <CheckCircleOutlined style={{ color: 'green' }} />
                  ) : (
                    <WarningOutlined style={{ color: 'orange' }} />
                  )}{' '}
                  {pump.name}: {pump.status ? 'On' : 'Off'}
                </List.Item>
              )}
              locale={{ emptyText: 'No pump status data available' }}
            />  
            <Divider orientation="left">Alerts</Divider>
            <div
              style={{
                width: '100%',
                maxHeight: '30em',
                overflowY: 'scroll',
                whiteSpace: 'nowrap',
              }}
            >
              <List
                size="large"
                locale={{ emptyText: 'No Alerts' }}
                header={<div>Overtemp Alerts</div>}
                bordered
                dataSource={overtempStats}
                renderItem={(alarm) => (
                  <List.Item>
                    <WarningOutlined style={{ color: 'red' }} /> {alarm.name} overtemp alarm set off at {alarm.time}
                  </List.Item>
                )}
              />
            </div>
            <div
              style={{
                width: '100%',
                maxHeight: '30em',
                overflowY: 'scroll',
                whiteSpace: 'nowrap',
              }}
            >
              <List
                size="large"
                locale={{ emptyText: 'No Alerts' }}
                header={<div>High Pressure Alerts</div>}
                bordered
                dataSource={highPressureStats}
                renderItem={(alarm) => (
                  <List.Item>
                    <WarningOutlined style={{ color: 'red' }} /> Alarm set off at {alarm.time}
                  </List.Item>
                )}
              />
            </div>
            <div
              style={{
                width: '100%',
                maxHeight: '30em',
                overflowY: 'scroll',
                whiteSpace: 'nowrap',
              }}
            >
              <List
                size="large"
                locale={{ emptyText: 'No Alerts' }}
                header={<div>Low Pressure Alerts</div>}
                bordered
                dataSource={lowPressureStats}
                renderItem={(alarm) => (
                  <List.Item>
                    <WarningOutlined style={{ color: 'red' }} /> Alarm set off at {alarm.time}
                  </List.Item>
                )}
              />
            </div>
            <div
              style={{
                width: '100%',
                maxHeight: '30em',
                overflowY: 'scroll',
                whiteSpace: 'nowrap',
              }}
            >
              <List
                size="large"
                locale={{ emptyText: 'No Alerts' }}
                header={<div>High Flow Alerts</div>}
                bordered
                dataSource={highFlowStats}
                renderItem={(alarm) => (
                  <List.Item>
                    <WarningOutlined style={{ color: 'red' }} /> Alarm set off at {alarm.time}
                  </List.Item>
                )}
              />
            </div>
            <div
              style={{
                width: '100%',
                maxHeight: '30em',
                overflowY: 'scroll',
                whiteSpace: 'nowrap',
              }}
            >
              <List
                size="large"
                locale={{ emptyText: 'No Alerts' }}
                header={<div>Phase Loss Alerts</div>}
                bordered
                dataSource={phaseLossStats}
                renderItem={(alarm) => (
                  <List.Item>
                    <WarningOutlined style={{ color: 'red' }} /> Alarm set off at {alarm.time}
                  </List.Item>
                )}
              />
            </div>
            <div
              style={{
                width: '100%',
                maxHeight: '30em',
                overflowY: 'scroll',
                whiteSpace: 'nowrap',
              }}
            >
              <List
                size="large"
                locale={{ emptyText: 'No Alerts' }}
                header={<div>Power Up Alerts</div>}
                bordered
                dataSource={powerUpStats}
                renderItem={(alarm) => (
                  <List.Item>
                    <WarningOutlined style={{ color: 'red' }} /> Alarm set off at {alarm.time}
                  </List.Item>
                )}
              />
            </div>
          </AntSpace>
        </div>
      </div>
    </div>
  ) : (
    <Spin
      style={{ justifyContent: 'center', width: '100%' }}
      tip="Loading..."
    />
  );
}
