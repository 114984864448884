import React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Spin } from 'antd';
import APIClient from '../../api.service';
import { Chart } from 'react-google-charts';
import { List, Button, Form, Select } from 'antd';
import moment from 'moment';
import ScheduleRadar from './SubMenus/ScheduleRadar';

export default function ScheduleTimeline() {
  const [data, setData] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const [options, setOptions] = React.useState({});
  const [config, setConfig] = React.useState([]);

  React.useEffect(() => {
    setGroups([
      { type: 'string', id: 'Satellite' },
      { type: 'string', id: 'Name' },
      { type: 'string', id: 'style', role: 'style' },
      { type: 'datetime', id: 'Start' },
      { type: 'datetime', id: 'End' },
    ]);

    let apiClient = new APIClient();

    var colorMap = {
      GREEN: '#252323',
      FAIRWAY: '#70798C',
      TEE: '#A99985',
    };

    var colors = [];
    apiClient.getConfiguration().then((response) => {
      apiClient.getCurrentSchedule().then((schedule) => {
        setData(
          schedule.map((values, index) => {
            return [
              'Satellite: ' + values.satelliteID,
              'S' + values.stationID,
              colorMap[
                response.stationTypes.find((x) => x.ID === values.stationTypeID)
                  .headType
              ],
              new Date(values.startTime.replace(/-/g, '/') + ' UTC'),
              new Date(values.endTime.replace(/-/g, '/') + ' UTC'),
            ];
          }),
        );
      });
    });

    setOptions({
      timeline: { colors: colors },
    });
  }, []);

  if (data.length > 0) {
    return (
      <div style={{ height: '100%' }}>
        <div style={{ height: '90%' }}>
          {data.length === 0 ? (
            <div></div>
          ) : (
            <Chart
              chartType="Timeline"
              data={[groups, ...data]}
              width="100%"
              height="100%"
              options={options}
            />
          )}
        </div>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              backgroundColor: '#A99985',
              textAlign: 'center',
              justifyContent: 'center',
              marginLeft: 'auto',
              paddingLeft: '.5em',
              paddingRight: '.5em',
            }}
          >
            <h3>TEE</h3>
          </div>
          <div
            style={{
              backgroundColor: '#70798C',
              textAlign: 'center',
              justifyContent: 'center',
              marginRight: '1em',
              marginLeft: '1em',
              paddingLeft: '.5em',
              paddingRight: '.5em',
            }}
          >
            <h3>FAIRWAY</h3>
          </div>
          <div
            style={{
              backgroundColor: '#252323',
              textAlign: 'center',
              justifyContent: 'center',
              marginRight: 'auto',
              paddingLeft: '.5em',
              paddingRight: '.5em',
            }}
          >
            <h3 style={{ color: 'white' }}>GREEN</h3>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Spin
        style={{ justifyContent: 'center', width: '100%' }}
        tip="Loading..."
      />
    );
  }
}
