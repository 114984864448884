import React, { Component } from 'react';
import {
  Modal,
  Button,
  List,
  Spin,
  Form,
  Input,
  Select,
  TimePicker,
  Switch,
} from 'antd';
import 'antd/dist/antd.css';
import APIClient from '../../../api.service';
import './Modals.css';
import moment from 'moment';

export default class StationModal extends Component {
  constructor(props) {
    super(props);
    
    // opens immediately on map click (autoShow=true), stays hidden in config screen until user clicks
    const initialShow = props.autoShow === true;
    
    this.state = {
      show: initialShow,
      stationTypeName: props.station.StationTypeName,
      stationTypeID: props.station.stationTypeID,
      stationStatus: props.station.stationStatus,
      actualRunTime: props.station.actualRunTime,
      flowRate: props.station.flowRate,
      parentID: props.station.parentID,
      priority: props.station.priority,
      runTime: props.station.runTime,
      useStationTypeRunTime: parseInt(props.station.useStationTypeRunTime),
      cycleAndSoak:
        props.station.soakTime != 0 && props.station.cycles > 0 ? true : false,
      soakTime: props.station.soakTime || 0,
      cycles:
        props.station.soakTime != 0 && props.station.cycles > 0
          ? props.station.cycles
          : 0,
      validateStationType: 'success',
      validateFlowRate: 'success',
      validatePriority: 'success',
      validateRunTime: 'success',
      validateUseStationRuntime: 'success',
      validateCycleAndSoak: 'success',
      validateSoakTime: 'success',
      validateCycles: 'success',
      isChild: props.station.parentID != null,
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleSubmit = () => {
    this.props.station.stationTypeID = this.state.stationTypeID;
    this.props.station.StationTypeName = this.state.stationTypeName;
    this.props.station.flowRate = this.state.flowRate;
    this.props.station.priority = this.state.priority;
    this.props.station.actualRunTime = this.state.actualRunTime;
    this.props.station.runTime = this.state.runTime;
    this.props.station.useStationTypeRunTime = this.state.useStationTypeRunTime;
    this.props.station.cycles = this.state.cycles;
    this.props.station.soakTime = this.state.soakTime;
    this.setState({
      validateSoakTime: 'success',
      validateCycles: 'success',
      validateUseStationRuntime: 'success',
      validateRunTime: 'success',
      validatePriority: 'success',
      validateFlowRate: 'success',
      validateStationType: 'success',
      validateCycleAndSoak: 'success',
    });

    const apiClient = new APIClient();
    apiClient.updateStation(this.props.station).then(() => {
      this.hideModal();
    });
  };

  handleCancel = () => {
    this.setState({ soakTime: this.props.station.soakTime });
    this.setState({ cycles: this.props.station.cycles });
    this.setState({
      useStationTypeRunTime: parseInt(this.props.station.useStationTypeRunTime),
    });
    this.setState({ runTime: this.props.station.runTime });
    this.setState({ actualRunTime: this.props.station.actualRunTime });
    this.setState({ priority: this.props.station.priority });
    this.setState({ flowRate: this.props.station.flowRate });
    this.setState({ stationTypeName: this.props.station.stationTypeName });
    this.setState({ stationTypeID: this.props.station.stationTypeID });
    this.setState({
      cycleAndSoak: this.props.station.soakTime != 0 ? true : false,
    });
    this.setState({ validateSoakTime: 'success' });
    this.setState({ validateCycles: 'success' });
    this.setState({ validateUseStationRuntime: 'success' });
    this.setState({ validateRunTime: 'success' });
    this.setState({ validatePriority: 'success' });
    this.setState({ validateFlowRate: 'success' });
    this.setState({ validateStationType: 'success' });
    this.setState({ validateCycleAndSoak: 'success' });
    this.hideModal();
  };

  setStationTypeID = (value) => {
    this.setState({ validateStationType: 'warning' });
    this.setState({ stationTypeID: value });
    this.setState({
      stationTypeName: this.props.stationTypes.find((x) => x.ID === value)
        .headType,
    });
  };

  setFlowRate = (value) => {
    this.setState({ validateFlowRate: 'warning' });
    this.setState({ flowRate: value.target.value });
  };

  setPriority = (value) => {
    this.setState({ validatePriority: 'warning' });
    this.setState({ priority: value.target.value });
  };

  setRunTime = (value) => {
    if (!this.state.useStationTypeRunTime) {
      this.setState({
        actualRunTime: value.diff(moment().startOf('day'), 'seconds'),
      });
    }
    this.setState({ validateRunTime: 'warning' });
    this.setState({ runTime: value.diff(moment().startOf('day'), 'seconds') });
  };

  setUseStationTypeRunTime = (value) => {
    if (value) {
      this.setState({
        actualRunTime: this.props.stationTypes.find(
          (x) => x.ID === this.state.stationTypeID,
        ).runTime,
      });
    } else {
      this.setState({ actualRunTime: this.state.runTime });
    }
    this.setState({ validateUseStationRuntime: 'warning' });
    this.setState({ useStationTypeRunTime: value === true ? 1 : 0 });
  };

  setCycleAndSoak = (value) => {
    if (!value) {
      this.setState({ cycles: 0 });
      this.setState({ soakTime: 0 });
      this.setState({ cycleAndSoak: false });
      this.setState({ validateCycleAndSoak: 'warning' });
    } else {
      this.setState({ cycles: 2 });
      this.setState({ soakTime: 60 });
      this.setState({ cycleAndSoak: true });
      this.setState({ validateCycleAndSoak: 'warning' });
    }
  };

  setSoakTime = (value) => {
    this.setState({ validateSoakTime: 'warning' });
    this.setState({ soakTime: value.diff(moment().startOf('day'), 'seconds') });
  };

  setCycles = (value) => {
    this.setState({ validateCycles: 'warning' });
    this.setState({ cycles: value });
  };

  render() {
    const formItemLayout = {
      labelCol: { span: 12 },
      wrapperCol: { span: 8 },
    };

    const cycleOptions = [];
    for (let i = 2; i <= 9; i++) {
      cycleOptions.push(
        <Select.Option key={i} value={i}>
          {i}
        </Select.Option>,
      );
    }

    return this.props.station ? (
      <div sx={{ width: '30rem', display: 'flex' }}>
        <Button
          data-testid="modalBtn"
          onClick={this.showModal}
          style={{
            textAlign: 'left',
            width: '28rem',
            height: '40px',
            marginBottom: '0px',
            padding: '0',
            borderStyle: 'none',
          }}
        >
          <List.Item.Meta
            title={
              <b>
                Satellite {this.props.station.satelliteID} - Station{' '}
                {this.props.station.stationID}{' '}
                {this.state.isChild ? 'child' : ''}
              </b>
            }
            description={
              <p>
                Priority: {this.props.station.priority} │ Flow Rate:{' '}
                {this.props.station.flowRate} │ Station Type:{' '}
                {this.props.station.StationTypeName}
              </p>
            }
          />
        </Button>
        <Modal
          title={
            'Satellite ' +
            this.props.station.satelliteID +
            ' - Station ' +
            this.props.station.stationID +
            (this.state.isChild ? ' child' : '')
          }
          centered
          visible={this.state.show}
          onCancel={this.handleCancel}
          width={500}
          okText="Save"
          getContainer={document.body}
          closable={false}
          okButtonProps={{
            form: 'station' + this.props.station.stationID,
            key: 'submit',
            htmlType: 'submit',
          }}
        >
          <Form
            id={'station' + this.props.station.stationID}
            onFinish={this.handleSubmit}
            {...formItemLayout}
          >
            <Form.Item
              label="Station Type:"
              validateStatus={this.state.validateStationType}
              hasFeedback
            >
              <Select
                data-testid="stationType"
                value={this.state.stationTypeID}
                onChange={this.setStationTypeID}
              >
                {this.props.stationTypes.map((stationType) => (
                  <Select.Option key={stationType.ID} value={stationType.ID}>
                    {stationType.headType}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Flowrate (GPM):"
              validateStatus={this.state.validateFlowRate}
              hasFeedback
            >
              <Input
                data-testid="flowRate"
                type="text"
                value={this.state.flowRate}
                onChange={this.setFlowRate}
              />
            </Form.Item>
            <Form.Item
              hidden={this.state.isChild}
              label="Priority:"
              validateStatus={this.state.validatePriority}
              hasFeedback
            >
              <Input
                type="text"
                value={this.state.priority}
                onChange={this.setPriority}
              />
            </Form.Item>
            <Form.Item
              hidden={this.state.isChild}
              label={
                'Use Station Type Runtime ' +
                moment('00:00:00', 'mm:ss')
                  .add(
                    this.props.stationTypes.find(
                      (x) => x.ID === this.state.stationTypeID,
                    ).runTime,
                    'seconds',
                  )
                  .format('mm:ss')
              }
            >
              <Switch
                data-testid="useStationTypeRuntime"
                checked={!!this.state.useStationTypeRunTime}
                onChange={this.setUseStationTypeRunTime}
              />
            </Form.Item>
            <Form.Item
              hidden={this.state.isChild}
              label="Run Time:"
              validateStatus={this.state.validateRunTime}
              hasFeedback
            >
              <TimePicker
                data-testid="runTime"
                disabled={this.state.useStationTypeRunTime ? true : false}
                showNow={false}
                format="mm:ss"
                minuteStep={1}
                secondStep={15}
                allowClear={false}
                onChange={this.setRunTime}
                value={moment('00:00:00', 'mm:ss').add(
                  this.state.runTime,
                  'seconds',
                )}
              />
            </Form.Item>
            <Form.Item hidden={this.state.isChild} label="Cycle & Soak">
              <Switch
                data-testid="cycleAndSoak"
                checked={this.state.cycleAndSoak}
                onChange={this.setCycleAndSoak}
              />
            </Form.Item>
            <Form.Item
              hidden={this.state.isChild}
              label="Soak Time:"
              validateStatus={this.state.validateSoakTime}
              hasFeedback
            >
              <TimePicker
                data-testid="soakTime"
                disabled={!this.state.cycleAndSoak}
                showNow={false}
                format="mm:ss"
                allowClear={false}
                onChange={this.setSoakTime}
                value={moment('00:00:00', 'mm:ss').add(
                  this.state.soakTime,
                  'seconds',
                )}
              />
            </Form.Item>
            <Form.Item
              hidden={this.state.isChild}
              label="Cycles:"
              validateStatus={this.state.validateCycles}
              hasFeedback
            >
              <Select
                data-testid="cycles"
                disabled={!this.state.cycleAndSoak}
                value={this.state.cycleAndSoak ? this.state.cycles || 2 : 0}
                onChange={this.setCycles}
              >
                {cycleOptions}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    ) : (
      <Spin
        style={{ justifyContent: 'center', width: '100%' }}
        tip="Loading..."
      />
    );
  }
}
