import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';

class CombinedChart extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  render() {
    const {
      pressureData = [],
      flowData = [],
      xAxisData = [],
      jockeyPumpData = [],
      pumpOneData = [],
      pumpTwoData = []
    } = this.props;

        if (xAxisData.length === 0) {
      return (
        <div style={{ 
          height: '500px', 
          width: '100%', 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          background: '#f0f2f5',
          borderRadius: '4px',
          flexDirection: 'column'
        }}>
          <div style={{ fontSize: '16px', color: '#999', marginBottom: '8px' }}>No data available</div>
          <div style={{ fontSize: '14px', color: '#aaa' }}>Please select a date range with available data</div>
        </div>
      );
    }

    const colors = {
      systemPressure: '#4285F4',    // Blue
      flow: '#0F9D58',              // Green
      pump1: '#DB4437',             // Red
      pump2: '#F4B400',             // Yellow
      pump3: '#9C27B0',             // Purple
      axis: '#333'                 // grey 
    };

    const option = {
      backgroundColor: '#fff',
      title: {
        text: 'Pumps in the ON state with System Pressure and Flow Rate',
        left: 'center',
        top: 10,
        textStyle: {
          fontSize: 20,
          fontWeight: 'bold',
          color: colors.axis
        }
      },

      legend: {
        data: [
          { name: 'System Pressure', icon: 'rect', itemStyle: { color: colors.systemPressure } },
          { name: 'Flow Rate', icon: 'rect', itemStyle: { color: colors.flow } },
          { name: 'Main Pump 1 ON', icon: 'rect', itemStyle: { color: colors.pump1 } },
          { name: 'Main Pump 2 ON', icon: 'rect', itemStyle: { color: colors.pump2 } },
          { name: 'Jockey Pump ON', icon: 'rect', itemStyle: { color: colors.pump3 } }
        ],
        // This legend is positioned end of the grid
        bottom: 60,
        left: 'center',
        orient: 'horizontal',
        itemWidth: 12,
        itemHeight: 8,
        itemGap: 10,
        textStyle: {
          fontSize: 12,
          color: colors.axis
        }
      },

      grid: {
        left: 70,
        right: 70,
        top: 70,
        bottom: 120,
        containLabel: true
      },

            // To make the Graph lines render like ECG
      animationDuration: 1000,
      animationEasing: 'cubicOut',

      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: { backgroundColor: '#6a7985' }
        },
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderColor: '#ccc',
        borderWidth: 1,
        padding: 10,
        textStyle: { color: '#333' },
        formatter: function (params) {
          let timeLabel = '';
          let systemPressureValue = null;
          let flowValue = null;
          let pump1On = false;
          let pump2On = false;
          let pump3On = false;

                    // shows up even if it has one data point and assigns a timelabel to it
          if (params.length) {
            timeLabel = params[0].axisValue;
          }

          params.forEach(item => {
            if (item.seriesName === 'System Pressure') {
              systemPressureValue = item.value;
            } else if (item.seriesName === 'Flow Rate') {
              flowValue = item.value;
            } else if (item.seriesName === 'Main Pump 1 ON' && item.value != null) {
              pump1On = true;
            } else if (item.seriesName === 'Main Pump 2 ON' && item.value != null) {
              pump2On = true;
            } else if (item.seriesName === 'Jockey Pump ON' && item.value != null) {
              pump3On = true;
            }
          });

          let html = `<div style="font-weight:bold; margin-bottom:5px;">${timeLabel}</div>`;
          if (systemPressureValue != null) {
            html += `<div style="margin:2px 0;">System Pressure: <strong>${systemPressureValue} PSI</strong></div>`;
          }
          if (flowValue != null) {
            html += `<div style="margin:2px 0;">Flow Rate: <strong>${flowValue} GPM</strong></div>`;
          }
          html += `<div style="margin:2px 0;">Pump 1: <strong>${pump1On ? 'ON' : 'OFF'}</strong></div>`;
          html += `<div style="margin:2px 0;">Pump 2: <strong>${pump2On ? 'ON' : 'OFF'}</strong></div>`;
          html += `<div style="margin:2px 0;">Jockey Pump: <strong>${pump3On ? 'ON' : 'OFF'}</strong></div>`;
          return html;
        }
      },

      xAxis: {
        type: 'category',
        data: xAxisData,
        boundaryGap: false,
        axisLine: { lineStyle: { color: colors.axis } },
        axisTick: { show: true },
        splitLine: { show: false },
        axisLabel: {
          formatter: value => value,
          margin: 12,
          align: 'center'
        },
        minInterval: 3600 * 1000 // hourly ticks
      },

      yAxis: [
        {
          name: 'Pressure (PSI)',
          type: 'value',
          min: -15,
          max: 100,
          // generating the label ticks at every 5 interval difference 
          interval: 5,
          axisTick: { show: false},
          axisLabel: {
            interval: 0,
            showMinLabel: true,
            showMaxLabel: true,
            formatter: function (val) {
              // Show mandatory labels for negative pump values
              if (val === -15) return 'Jockey Pump';
              if (val === -10) return 'Main Pump 2';
              if (val === -5)  return 'Main Pump 1';
      
              // Once it is above 0 it, should show at 20 interval and in the negative integer it should at 5 difference to get pump labels 
              if (val >= 0 && val % 20 === 0) {
                return val; 
              }
              return '';
            }
          },
        },
        {
          name: 'Flow Rate (GPM)',
          type: 'value',
          min: -105,
          max: 700,
          position: 'right',
          axisLine: { lineStyle: { color: '#333' } },
          splitLine: { show: false },
          axisLabel: {
            formatter: val => val < 0 ? '' : val
          }
        }
      ],

      series: [
        {
          name: 'System Pressure',
          type: 'line',
          yAxisIndex: 0,
          data: pressureData,
          symbol: 'none',
          step: 'end',
          lineStyle: { width: 2, color: colors.systemPressure }
        },
        {
          name: 'Flow Rate',
          type: 'line',
          yAxisIndex: 1,
          data: flowData,
          symbol: 'none',
          step: 'end',
          lineStyle: { width: 2, color: colors.flow }
        },
        {
          name: 'Main Pump 1 ON',
          type: 'line',
          yAxisIndex: 0,
          data: pumpOneData.map(val => (val ? -5 : null)),
          symbol: 'none',
          step: 'start',
          lineStyle: { width: 4, color: colors.pump1 }
        },
        {
          name: 'Main Pump 2 ON',
          type: 'line',
          yAxisIndex: 0,
          data: pumpTwoData.map(val => (val ? -10 : null)),
          symbol: 'none',
          step: 'start',
          lineStyle: { width: 4, color: colors.pump2 }
        },
        {
          name: 'Jockey Pump ON',
          type: 'line',
          yAxisIndex: 0,
          data: jockeyPumpData.map(val => (val ? -15 : null)),
          symbol: 'none',
          step: 'start',
          lineStyle: { width: 4, color: colors.pump3 }
        }
      ],

      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
          zoomOnMouseWheel: true
        },
        {
          show: true,
          type: 'slider',
          bottom: 10,
          height: 20,
          start: 0,
          end: 100,
          handleIcon: 'M8.2,13.6V3.9H6.3v9.7H3.1L7.1,17l4-3.4H8.2z M9.7,8.9V18.6H11.6V8.9H14.9L10.9,5.5L6.9,8.9H9.7z',
          handleSize: '80%',
          showDetail: true
        }
      ],
    };

    if (xAxisData.length > 100) {
      option.series.forEach(series => {
        if (series.type === 'line') {
          series.sampling = 'lttb';
        }
      });
    }

    return (
      <ReactEcharts
        option={option}
        style={{ height: '500px', width: '100%' }}
        opts={{ renderer: 'canvas' }}
        notMerge={true}
      />
    );
  }
}

export default CombinedChart;