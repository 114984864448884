import { Button, notification } from 'antd';
import { useEffect } from 'react';

export default function LongPressToast() {
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    openNotification();
  }, []);

  function doNotShowAgain() {
    localStorage.setItem('noLongPressInstructions', 'true');
    notification.destroy();
  }

  function openNotification() {
    const btn = (
      <Button type="link" size="small" onClick={doNotShowAgain}>
        Do not show again
      </Button>
    );

    api.info({
      showProgress: true,
      description:
        'Long press on a single station to select multiple stations at once',
      btn,
      placement: 'bottom',
    });
  }

  return <>{contextHolder}</>;
}
