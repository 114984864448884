import axios from 'axios';
import appConfig from './config';

const BASE_URI = appConfig.REACT_APP_BASE_URL;

const client = axios.create({
  baseURL: BASE_URI,
  json: true,
});

export default class APIClient {
  constructor() {
    if (sessionStorage.getItem('refreshToken')) {
      this.refreshToken = sessionStorage.getItem('refreshToken');
    } else if (localStorage.getItem('refreshToken')) {
      this.refreshToken = localStorage.getItem('refreshToken');
    }
  }

  async login(username, password) {
    let response = this.perform(
      'post',
      '/auth',
      { username: username, password: password },
      'login',
    );
    return await response;
  }

  ////////////////////////////////////////////////
  ////             Requests                   ////
  ////////////////////////////////////////////////
  getConfiguration() {
    return this.perform('get', '/configuration');
  }
  setConfiguration(data) {
    return this.perform('put', '/configuration', data);
  }

  updateSatellite(satellite) {
    return this.perform('put', '/satellites?ID=' + satellite.ID, satellite);
  }

  updateStation(station) {
    return this.perform('put', '/stations?ID=' + station.ID, station);
  }

  updateStationType(stationType) {
    return this.perform(
      'put',
      '/stationTypes?ID=' + stationType.ID,
      stationType,
    );
  }

  createNewStationType(stationType) {
    return this.perform('post', '/stationTypes', stationType);
  }

  getSavedSchedules() {
    return this.perform('get', '/schedules');
  }

  updateSavedSchedule(schedule) {
    return this.perform('put', '/schedules?ID=' + schedule.ID, schedule);
  }

  saveNewSchedule(schedule) {
    return this.perform('put', '/schedules', schedule);
  }

  getCurrentSchedule() {
    return this.perform('get', '/currentSchedule');
  }

  commitSchedule() {
    return this.perform('post', '/commands', { function: 'commit' });
  }

  toggleStationOn(id) {
    return this.perform('post', '/commands', {
      function: 'toggle_station',
      station_id: parseInt(id),
      action: 'start',
    });
  }

  toggleStationOff(id) {
    return this.perform('post', '/commands', {
      function: 'toggle_station',
      station_id: parseInt(id),
      action: 'stop',
    });
  }
  reEnableStation(id) {
    return this.perform('post', '/commands', {
      function: 'toggle_station',
      station_id: parseInt(id),
      action: 'enable',
    });
  }

  getSystemStats() {
    return this.perform('get', '/systemStats');
  }

  getSatelliteCellStatuses() {
    return this.perform('get', '/satellite_cell_status');
  }

  uploadFirmware(firmware) {
    return this.perform('post', '/firmware_upload', firmware);
  }

  getUserRole() {
    return this.perform('get', '/user_role');
  }

  getPumpHouseData() {
    return this.perform('get', '/pump_house_data');
  }

  ////////////////////////////////////////////////
  ////          Perform HTTP Request          ////
  ////////////////////////////////////////////////
  async perform(method, resource, data, type) {
    let headerValues = {};

    switch (type) {
      case 'form':
        break;
      case 'admin':
        break;
      case 'login':
        let auth = 'Basic ' + btoa(data.username + ':' + data.password);
        headerValues.Authorization = auth;
        break;
      default:
        headerValues = {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          'Authorization-Refresh': `Bearer ${this.refreshToken}`,
        };
        break;
    }

    return client({
      method,
      url: resource,
      data,
      headers: headerValues,
    }).then(function (response) {
      return response.data;
    });
  }

  //Get the current rain hold status
  getRainHoldStatus() {
    return this.perform('get', '/rain_hold_status')
      .then(response => response.active)
      .catch(() => false);
  }

  //Set the rain hold status
  setRainHoldStatus(active) {
    return this.perform('post', '/rain_hold_status', { active })
      .then(response => response.success)
      .catch(() => false);
  }

/**
 * Initiates a test sequence for all stations under the specified satellite.
 *
 * @param {number|string} satelliteId - The ID of the satellite to test.
 * @param {number} duration - Duration in seconds for each station's test (default is 10).
 * @returns {Promise<Object>} A promise that resolves with the server's response object.
 *
 * Note: Errors are logged as they could be captured by sentry.
 */
testSatellite(satelliteId, duration = 10) {
  const payload = {
    function: 'test_satellite',
    satellite_id: satelliteId,
    duration: duration,
  };

  return this.perform('post', '/test_sequence', payload)
    .then((res) => res)
    .catch((err) => {
      console.error('[Satellite Test] Error:', err);
      throw err;
    });
}
}
