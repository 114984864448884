import React, { Component } from 'react';
import { Spin, List } from 'antd';
import 'antd/dist/antd.css';
import SatelliteModal from '../Modals/SatelliteModal';
import './Satellites.css';

export default class Satellites extends Component {
  constructor(props) {
    super();

    this.state = {
      validateSatellites: 'success',
    };
  }

  render() {
    const sortedSatellites = [...this.props.satellites];
    sortedSatellites.sort((a, b) => Number(a.ID) - Number(b.ID));
    return sortedSatellites.length > 0 ? (
      <div>
        <List
          itemLayout="horizontal"
          dataSource={sortedSatellites}
          renderItem={(satellite) => (
            <List.Item key={satellite.ID}>
              <SatelliteModal
                satellite={satellite}
                stations={this.props.stations}
                stationTypes={this.props.stationTypes}
              />
            </List.Item>
          )}
        />
      </div>
    ) : (
      <Spin className="spinner" tip="Loading..." />
    );
  }
}
