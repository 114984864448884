import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';
import ReactMapGL, { Marker, useControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import CircleSharpIcon from '@mui/icons-material/CircleSharp';
import { Spin, Slider } from 'antd';
import moment from 'moment';
import appConfig from '../../../config';

mapboxgl.accessToken = appConfig.REACT_APP_MAPBOX_TOKEN;

export default class ScheduleRadar extends Component {
  constructor(props) {
    super();

    this.state = {
      lng: appConfig.REACT_APP_LON,
      lat: appConfig.REACT_APP_LAT,
      zoom: 15.95,
      startTime: moment(
        props.stations.sort((a, b) =>
          moment(moment.utc(a.startTime).local()) >
          moment(moment.utc(b.startTime).local())
            ? 1
            : -1,
        )[0].startTime,
      ),
      currentTime: moment(
        props.stations.sort((a, b) =>
          moment(moment.utc(a.startTime).local()) >
          moment(moment.utc(b.startTime).local())
            ? 1
            : -1,
        )[0].startTime,
      ),
    };
  }

  modifySimTime = (value) => {
    this.setState({ currentTime: moment(this.state.startTime) });
    this.setState({
      currentTime: this.state.currentTime.add(value, 'minutes'),
    });
  };

  render() {
    let currentTime = this.state.currentTime;
    return this.props.stations ? (
      <ReactMapGL
        initialViewState={{
          longitude: this.state.lng,
          latitude: this.state.lat,
          zoom: this.state.zoom,
        }}
        mapStyle="mapbox://styles/mapbox/satellite-v9"
        mapboxApiAccessToken={appConfig.REACT_APP_MAPBOX_TOKEN}
      >
        {this.props.stations.map(function (station, index) {
          if (
            currentTime.isBetween(
              moment(new Date(station.startTime)),
              moment(new Date(station.endTime)),
            )
          ) {
            return (
              <Marker
                scale={90}
                key={index}
                longitude={station.longitude}
                latitude={station.latitude}
                anchor="bottom"
              >
                <div
                  className="mapMarkerStyle"
                  style={{
                    padding: '0px',
                    margin: '0px',
                    color: '#6495ED',
                    cursor: 'pointer',
                    opacity: '0.5',
                  }}
                >
                  <CircleSharpIcon
                    style={{ fontSize: '8em' }}
                  ></CircleSharpIcon>
                </div>
              </Marker>
            );
          }
        })}
        <div
          style={{
            position: 'absolute',
            left: '35%',
            top: '92%',
            transform: 'translate(-50%, -50%)',
            width: '29%',
            transform: 'scale(3)',
          }}
        >
          <Slider
            min={0}
            max={720}
            defaultValue={0}
            onChange={this.modifySimTime}
            tooltipVisible={false}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            left: '83%',
            top: '3%',
            transform: 'translate(-50%, -50%)',
            transform: 'scale(3)',
          }}
        >
          <h2 style={{ color: 'yellow' }}>
            {moment(this.state.currentTime)
              .subtract(4, 'hours')
              .format('h:mm:ss a')}
          </h2>
        </div>
      </ReactMapGL>
    ) : (
      <Spin
        style={{ justifyContent: 'center', width: '100%' }}
        tip="Loading..."
      />
    );
  }
}
